<template>
    <div class="rank">
      <div class="thisDay">
        <div class="this-data">
          <p class="this-t">积分</p>
          <p class="this-num">{{ac.activityUserIntegral.totalIntegral}}</p>
        </div>
        <div class="this-data">
          <p class="this-t">排名</p>
          <p class="this-num">{{ac.randIndex}}</p>
        </div>
        <img v-if="!info.avatar" class="headImg" src="../../image/first/head.jpg" />
        <img v-if="info.avatar" class="headImg" :src="info.avatar" />
      </div>
      <div class="cn">
        <div class="menu">
          <p class="m-1">排名</p>
          <p class="m-2">昵称</p>
          <p class="m-3">积分</p>
        </div>
        <div class="list">
          <div class="item" v-for="(it, index) in ac.list" :key="it.id">
            <p class="m-1">{{index + 1}}</p>
            <p class="m-2">{{it.username}}</p>
            <p class="m-3">{{it.totalIntegral}}</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import { formatDate, getQuery } from '../../common/util';
export default {
  props: ['title'],
  components: {
    
  },
  data () {
      return {
        info: {},
        ac: {
          activityUserIntegral: {
            totalIntegral: 0
          },
          randIndex: 0,
        },
        list: [1,2,3,4,5,6,7,8,9,10,11,12,13,14]
      }
  },
  mounted() {
    this.id = getQuery('id');
    let info = localStorage.getItem('ng-data');
    if (info) {
      this.info = JSON.parse(info);
    }
    this.getRank();
  },
  methods: {
    // 获取数据
    getRank() {
      this.$http.get(`/api/activity/${this.id}/rank`, {
      })
      .then((res) => {
        this.ac = res.data.data;
        if (!this.ac?.isAnswered) {
          alert('您还未参与该活动');
        }
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    }
  }

}
</script>

<style lang="less" scoped>
    .rank {
      min-height: 100vh;
      background-color: #79cdca;
      box-sizing: border-box;
      padding: 0.42rem;
      .thisDay {
        display: flex;
        background-color: white;
        border-radius: 0.12rem;
        padding: 0.3rem 0;
        margin-top: 0.45rem;
        position: relative;
        .headImg {
          position: absolute;
          top: -0.3rem;
          left: 50%;
          transform: translateX(-50%);
          width: 1.2rem;
          height: 1.2rem;
          border-radius: 1.2rem;
        }
        .this-data {
          width: 50%;
          text-align: center;
          .this-t {
            font-size: 0.26rem;
          }
          .this-num {
            font-size: 0.42rem;
            height: 0.5rem;
            width: 0.8rem;
            margin: 0.06rem auto 0;
            border-top: 1px solid #f4f6f6;
          }
        }
      }
      .cn {
        margin-top: 0.3rem;
        border-radius: 0.12rem;
        overflow: hidden;
        .menu {
          background-color: #709dc7;
          height: 1rem;
          display: flex;
          color: white;
          font-size: 0.32rem;
          text-align: center;
          >p {
            height: 100%;
            line-height: 1rem;
          }
          .m-1 {
            width: 20%;
          }
          .m-2 {
            width: 60%;
            text-align: left;
          }
          .m-3 {
            width: 20%;
          }
        }
        .list {
          max-height: 9rem;
          overflow-y: scroll;
          .item {
            background-color: white;
            height: 1rem;
            display: flex;
            font-size: 0.32rem;
            text-align: center;
            border-bottom: 1px solid #d9d9d9;
            >p {
              height: 100%;
              line-height: 1rem;
            }
            .m-1 {
              width: 20%;
            }
            .m-2 {
              width: 60%;
              text-align: left;
            }
            .m-3 {
              width: 20%;
            }
          }
        }
      }
    }
</style>
